<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        알림 설정
      </h6>

      <!-- <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          v-model="userData.pushSetting.network"
          switch
          inline
          
        >
          <span>네트워크 알림</span>
        </b-form-checkbox>

        <b-col
          v-if="userData.pushSetting.network === true"          
        >
          <b-row
            v-for="userDevice of userDevices"
            :key="userDevice._id">
            <span class="mx-1 mt-1 float-left col-3"> {{ userDevice.name }} </span>
            <b-form-checkbox
              v-model="userDevice.pushSetting"
              switch
              inline
              class="mr-1 mt-1 float-left col-2"
            />
          </b-row>
        </b-col> 
      </b-col> -->

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          v-model="userData.pushSetting.automatic"
          switch
          inline
        >
          <span>자동제어 알림 <p style="color:red">자동화 동작 실패 알림은 <br>[자동제어 알림] 해제 여부와 상관없이 수신됩니다. <br> 수신을 원하지 않으시면 <br>장치 자동화 해제를 해주시기 바랍니다.</p> </span>
        </b-form-checkbox>
      </b-col>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          v-model="userData.pushSetting.sensor"
          switch
          inline
          disabled
        >
          <span>센서 알림 (미적용)</span>          
        </b-form-checkbox> 

        <b-col
          v-if="userData.pushSetting.sensor === true"
        >
          <!-- <b-row
           v-if="userSensors.indexOf('temperature') !== -1"
          >
            <span class="mx-1 mt-1 float-left col-3">온도</span>
            <b-form-checkbox
              v-model="temperature"
              switch
              disabled
              inline
              class="mr-1 mt-1 float-left col-2"
            />
            <input v-if="temperature === true" v-model="userData.pushSetting.sensor_temp" disabled type="number" id="temperature" placeholder="00" class="mr-1 mt-1 float-left col-2">
            <span v-if="temperature === true" class="mt-1 float-left">이상</span>
          </b-row>   -->

          <b-row
            v-if="userSensors.indexOf('hs2') !== -1"
          >
            <span class="mx-1 mt-1 float-left col-3">황화수소</span>
            <b-form-checkbox
              v-model="hs2"
              switch
              inline
              class="mr-1 mt-1 float-left col-2"
            />
            <input v-if="hs2 === true" v-model="userData.pushSetting.sensor_hs" type="number" id="hydrogen_sulfide" placeholder="00" class="mr-1 mt-1 float-left col-2">
            <span v-if="hs2 === true" class="mt-1 float-left">이상</span>
          </b-row>  

          <b-row
            v-if="userSensors.indexOf('nh3') !== -1"
          >
            <span class="mx-1 mt-1 float-left col-3">암모니아</span>
            <b-form-checkbox
              v-model="nh3"
              switch
              inline
              class="mr-1 mt-1 float-left col-2"
            />
            <input v-if="nh3 === true" v-model="userData.pushSetting.sensor_nh" type="number" id="ammonia" placeholder="00" class="mr-1 mt-1 float-left col-2">
            <span v-if="nh3 === true" class="mt-1 float-left">이상</span>
          </b-row>

          <b-row
            v-if="userSensors.indexOf('rain') !== -1"
          >
            <span class="mx-1 mt-1 float-left col-3">우적</span>
            <b-form-checkbox
              v-model="userData.pushSetting.rain"
              switch
              inline
              class="mr-1 mt-1 float-left col-2"
            />
          </b-row>

          <b-row
            v-if="userSensors.indexOf('temperature') === -1 && userSensor.indexOf('hs2') === -1 && userSensor.indexOf('nh3') === -1 && userSensor.indexOf('rain') === -1"
          >
            <span class="mx-1 mt-1 float-left">등록된 센서가 없습니다.</span>
          </b-row>

        </b-col>   

      </b-col>

      <!-- buttons -->
      <b-col cols="12">
        <b-button
          variant="primary"
          class="mr-1 mt-1 float-right"
          @click="updateInfo"
        >
          설정 변경
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    userSensor: {
      type: Array,
      required: true,
    },
    userDevice: {
      type: Array,
      required: true,
    },
    userButton: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userSensors: this.userSensor,
      userDevices: this.userDevice,
      temperature: (this.userData.pushSetting.sensor_temp !== null) ? true : false,
      hs2: (this.userData.pushSetting.sensor_hs !== null) ? true : false,
      nh3: (this.userData.pushSetting.sensor_nh !== null) ? true : false,
    }
  },
  methods: {
    updateInfo() {
      if(this.userSensors.indexOf("temperature") === -1){
        this.temperature = false
      }
      if(this.userSensors.indexOf("hs2") === -1){
        this.hs2 = false
      }
      if(this.userSensors.indexOf("nh3") === -1){
        this.nh3 = false
      }
      if(this.userSensors.indexOf("rain") === -1){
        this.userData.pushSetting.rain = false
      }
      if(this.userSensors.indexOf("temperature") === -1 && this.userSensors.indexOf("hs2") === -1 && this.userSensors.indexOf("nh3") === -1 && this.userSensors.indexOf("rain") === -1){
        this.userData.pushSetting.sensor = false
      }      

      if(this.userData.pushSetting.network === false) {
        for(let i = 0 ; i < this.userDevices.length ; i++) {
          this.userDevices[i].pushSetting = false
        }
      }
      let devicePush = false
      for(let i = 0 ; i < this.userDevices.length ; i++) {
        if(this.userDevices[i].pushSetting === true) {
          devicePush = true
        }
      }
      this.userData.pushSetting.network = devicePush

      if(this.userData.pushSetting.sensor === false || this.temperature === false 
      || this.userData.pushSetting.sensor_temp === "" || this.userData.pushSetting.sensor_temp === null){
        this.userData.pushSetting.sensor_temp = null
        this.temperature = false
      }
      if(this.userData.pushSetting.sensor === false || this.hs2 === false 
      || this.userData.pushSetting.sensor_hs === "" || this.userData.pushSetting.sensor_hs === null){
        this.userData.pushSetting.sensor_hs = null
        this.hs2 = false
      }
      if(this.userData.pushSetting.sensor === false || this.nh3 === false 
      || this.userData.pushSetting.sensor_nh === "" || this.userData.pushSetting.sensor_nh === null){
        this.userData.pushSetting.sensor_nh = null
        this.nh3 = false
      }
      if(this.userData.pushSetting.sensor === false){
        this.userData.pushSetting.rain = false
      }
      if(this.temperature === false && this.hs2 === false && this.nh3 === false && this.userData.pushSetting.rain === false){
        this.userData.pushSetting.sensor = false
      }

      let data = [this.userData, this.userDevices]
      store.dispatch('users/updateUserSetting', { id: this.userId, queryBody: data })
        .then(() => {
          this.$bvModal.msgBoxOk('알림 설정이 수정되었습니다', {
            title: '알림 설정',
            centered: true,
          }).then(() => {
            this.$router.push({ name: 'remote-control' })
          })
          
        })
        .catch(error => {
          console.log(error)
          this.$bvModal.msgBoxOk('알림 설정이 수정되지 않았습니다.', {
            title: '알림 설정',
            centered: true,
          }).then({})
        })
    },
  },
}
</script>