<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-mid"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">개인정보</span>
      </template>

      <account-setting-general
        v-if="userData"
        :user-id="userId"
        :user-data="userData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- notification -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">알림 설정</span>
      </template>

      <account-setting-notification
        v-if="userData"
        :user-id="userId"
        :user-data="userData"
        :user-sensor="userSensor"
        :user-device="userDevice"
        :user-button="userButton"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import store from '@/store'
import { ref } from '@vue/composition-api'

import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingNotification,
  },
  setup() {
    const userData = ref(null)

    const userId = getUserData().id
    let userSensor = []
    let userDevice = []
    let userButton = []
    store.dispatch('users/fetchUser', { id: userId })
      .then(response => {
        userData.value = response.data
        store.dispatch('sensor/fetchSensors', { userId })
        .then(sensor => {
          if(sensor.data.length > 0){
            for(let i = 0; i < sensor.data.length; i++){
              userSensor.push(sensor.data[i].type)
            }
          }
          
          store.dispatch('device/fetchDevices', { userId })
          .then(device => {
            if(device.data.length > 0){
              device.data = device.data.filter(el => el.type !== '가상보드')
              device.data.forEach(el => userDevice.push(el))
              
              store.dispatch('button/fetchButtons', { userId })
              .then(button => {
                let coolerArr = button.data.filter(el => el.type === 'cooler_sh')
                if(coolerArr.length > 0) {
                  coolerArr.forEach(el => el.pushSetting = false)
                  userButton = userButton.concat(coolerArr)
                }
              })
            }
          })
        })
      })
      .catch(error => {
        console.log(error)
      })

    return {
      userId,
      userData,
      userSensor,
      userDevice,
      userButton
    }
  },
}
</script>
