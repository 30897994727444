<template>
  <b-card>

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="이름"
            label-for="name"
          >
            <b-form-input
              v-model="userData.userInfo.name"
              name="name"
              placeholder="Name"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="전화번호"
            label-for="phone"
          >
            <b-form-input
              v-model="userData.userInfo.phone"
              name="phone"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="농장 주소"
            label-for="address"
          >
            <b-form-input
              v-model="userData.userInfo.address"
              name="address"
              placeholder="Address"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mt-2 mr-1 float-right"
            @click="requestDeleteAccount"
          >
            회원 탈퇴
          </b-button>
        </b-col>

        <!-- <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1 float-right"
            @click="updateInfo"
            disabled
          >
            정보 수정
          </b-button>
        </b-col> -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { logout } from '@/auth/utils'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  created() {
    
  },
  methods: {
    async updateStatus() {
      try {
        this.userData.userInfo.status = 'inactive'
        await store.dispatch('users/updateUser', { id: this.userId, queryBody: this.userData })
      } catch (error) {
        this.feedbackAlert('계정 비활성화', `에러 : ${error.message}`)
      }
      
    },
    async requestDeleteAccount () {
      this.$bvModal.msgBoxConfirm('계정을 비활성화 및 탈퇴 하시겠습니까?', {
        title: '탈퇴 확인',
        centered: true,
      }).then(async  () => {
        await this.sendMsgToMaster('탈퇴요청', `사용자 : ${this.userData.userInfo.name} / 번호 : ${this.userData.userInfo.phone} \\n (( 복구 )) ==> 접근 권한 '허용' 체크`)
        await this.updateStatus()
        logout()
      })
      
    },
    async sendMsgToMaster(title, description) {
      try {
        await store.dispatch('users/sendNoticeMsg', { queryBody: {
          title,
          description,
          role: 'customer',
          target: ['608ba42884dbef210a340c43']
        } })
      } catch (error) {
        this.feedbackAlert('탈퇴 요청', `에러 : ${error.message}`)
      }
    },
    async feedbackAlert(title, message) {
      this.$bvModal.msgBoxOk(message, {
        title,
        centered: true,
      }).then(() => {})
    }
  },
}
</script>
